import styled from 'styled-components'

export default styled.div`
  & .react-international-phone-input-container {
    gap: 12px;

    .react-international-phone-country-selector {
      .react-international-phone-country-selector-button {
        background: #f2f2f2;
        border: none;
        border-radius: 8px;
        width: 80px;
        height: 100%;

        .react-international-phone-flag-emoji {
          margin: 0 8px 0 0;

          & + div {
            margin: 0;
            border-top-color: var(--Black, #000);
            border-width: 6px;
            border-bottom-width: 0;
          }
        }
      }

      .react-international-phone-country-selector-dropdown {
        border-radius: 8px;
        top: 50px;
        z-index: 2;

        li {
          font-family: Montserrat;
          height: 36px;
        }
      }
    }

    .react-international-phone-input {
      flex: 1;
      background: #f2f2f2;
      border: none;
      border-radius: 8px;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      height: 50px;
      padding: 0 15px;
      overflow: hidden;
      width: 1px;
    }
  }
`
