import parsePhoneNumber from 'libphonenumber-js'

export const redactEmail = email => {
  const [localPart, domain] = email.split('@')
  if (localPart.length > 1) {
    const redactedLocalPart = localPart[0] + '•'.repeat(localPart.length - 1)
    return `${redactedLocalPart}@${domain}`
  }

  return email
}

export const redactPhoneNumber = phoneNumber => {
  if (phoneNumber && phoneNumber.length > 7) {
    const parsedNumber = parsePhoneNumber(phoneNumber).format('INTERNATIONAL')
    const partNumbers = parsedNumber.split(' ')
    const prefix = partNumbers[0]
    const localNumber = partNumbers
      .slice(1)
      .join('')
      .replace(/[^0-9]+/g, '')

    return `${prefix} ${'•'.repeat(3)} ${'•'.repeat(3)} ${localNumber.slice(6)}`
  }

  return phoneNumber
}
