import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import notif from 'services/notification'
import { show, hide, types } from 'store/modals'
import { withFormValidator } from 'utils/validator'
import LINKS from 'const/links'
import noScroll from 'no-scroll'

import { requestVerify, selectVerify } from 'store/smsv'

import {
  register,
  login,
  selectIsRegistering,
  selectIsAuthenticating,
} from 'auth/modules/auth'

import { unsetGuestCheckoutEmail } from 'store/guest-checkout'

const verifCodeRegex = /^[0-9]{4}$/

export default compose(
  connect(
    state => ({
      isAuthenticating:
        selectIsRegistering(state) || selectIsAuthenticating(state),
      verifyState: selectVerify(state),
    }),
    (dispatch, { redirectFrom, auth, onSuccess }) => ({
      onSubmit: ({ verificationCode }) => {
        if (redirectFrom === types.REGISTER_V2) {
          const authParams = JSON.parse(auth)

          dispatch(register({ ...authParams, otp: verificationCode }))
            .then(dispatch(unsetGuestCheckoutEmail()))
            .then(() => {
              onSuccess()
              dispatch(hide())
            })
            .catch(error => {
              notif.error({
                title: 'Registration failed',
                message: error.response.data.message,
                error,
              })
            })
        }

        if (
          redirectFrom === types.LOGIN_V2 ||
          redirectFrom === types.SMS_SEND_CODE
        ) {
          const authParams = JSON.parse(auth)

          dispatch(login({ ...authParams, otp: verificationCode }))
            .then(dispatch(unsetGuestCheckoutEmail()))
            .then(() => {
              onSuccess()
              dispatch(hide())
            })
            .catch(error => {
              notif.error({
                title: 'Login failed',
                message: error.response.data.status.message,
                error,
              })
            })
        }

        if (redirectFrom === 'HOMEPAGE') {
          dispatch(requestVerify(verificationCode))
            .then(() => {
              notif.success({
                title: 'Success',
                message: 'Mobile number verified',
              })

              dispatch(hide())
            })
            .catch(error => {
              notif.error({
                title: 'SMS verification failed',
                message: error.response.data.message,
              })
            })
        }

        // dispatch(requestVerify(verificationCode))
        //   .then(({ requestCode, mobile }) => {
        //     notif.success({
        //       title: 'Success',
        //       message: 'Mobile number verified',
        //     })

        //     if (redirectFrom === types.REGISTER_V2)
        //       dispatch(
        //         show(types.REGISTER_V2_LAST_STEP, {
        //           ...JSON.parse(auth),
        //           mobile,
        //           requestCode,
        //           onSuccess,
        //         }),
        //       )

        //     if (redirectFrom === types.LOGIN_V2) {
        //       dispatch(login({ ...JSON.parse(auth), requestCode }))
        //         .then(dispatch(unsetGuestCheckoutEmail()))
        //         .then(onSuccess)
        //     }

        //     if (redirectFrom === 'HOMEPAGE') {
        //       dispatch(hide())
        //     }
        //   })
        //   .catch(error => {
        //     console.log(error)
        //     notif.error({
        //       title: 'SMS verification failed',
        //       message: error.response.data.message,
        //     })
        //   })
      },
      handleHide: () => {
        dispatch(hide())
        noScroll.off()
      },
      show: (type, params) => dispatch(show(type, params)),
    }),
  ),

  withProps(({ show, redirectFrom, auth, onSuccess }) => ({
    handleBack: () => {
      if (redirectFrom === types.REGISTER_V2) {
        const { email, mobile } = JSON.parse(auth)
        show(types.REGISTER_V2, { email, mobile })
      }

      if (redirectFrom === types.SMS_SEND_CODE) {
        show(types.SMS_SEND_CODE, {
          redirectFrom: types.SMS_VERIFY,
          auth,
          onSuccess,
        })
      }

      if (redirectFrom === types.LOGIN_V2) {
        window.location.href = LINKS.FAQ
      }

      if (redirectFrom === 'HOMEPAGE') {
        show(types.SMS_SEND_CODE, {
          redirectFrom,
          auth,
        })
      }
    },
    onSubmitFail: () =>
      notif.error({
        title: 'SMS verification failed',
        message: 'please check the form errors',
      }),
  })),

  withFormValidator({
    verificationCode: v =>
      verifCodeRegex.test(v) ? undefined : 'Incomplete verification code',
  }),
)
