import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import notif from 'services/notification'
import { show, hide, types } from 'store/modals'
import get from 'lodash/get'
import noScroll from 'no-scroll'

import { requestSendCode, selectSendCode } from 'store/smsv'

import { login, selectIsAuthenticating } from 'auth/modules/auth'

import {
  withFormValidator,
  isRequired,
  isMobileNum,
  composeValidators as composeV,
} from 'utils/validator'

export default compose(
  connect(
    state => ({
      isAuthenticating: selectIsAuthenticating(state),
      sendCodeState: selectSendCode(state),
    }),
    {
      requestSendCode,
      login,
      hide,
      show,
    },
  ),

  withProps(
    ({
      show,
      hide,
      requestSendCode,
      login,
      redirectFrom,
      auth,
      onSuccess,
    }) => ({
      onSubmit: ({ mobile }) => {
        if (
          redirectFrom === types.LOGIN_V2 ||
          redirectFrom === types.SMS_VERIFY
        ) {
          const { email, password } = JSON.parse(auth)

          login({ email, password, mobile })
            .then(onSuccess)
            .catch(error => {
              const errorCode = get(error, 'response.data.status.code', 400)

              switch (errorCode) {
                case 110013:
                  show(types.SMS_VERIFY, {
                    redirectFrom: types.SMS_SEND_CODE,
                    auth: JSON.stringify({ email, password, mobile }),
                    onSuccess,
                  })
                  break
                default:
                  notif.error({
                    title: 'Sending SMS failed!',
                    message: 'please try again',
                    error,
                  })
              }
            })
        }

        if (redirectFrom === 'HOMEPAGE') {
          requestSendCode(mobile)
            .then(() => {
              show(types.SMS_VERIFY, {
                redirectFrom,
                auth: JSON.stringify({ mobile }),
              })
            })
            .catch(error => {
              notif.error({
                title: 'Failed to send SMS verification code',
                message: getErrorMessage(error),
              })
            })
        }
      },
      handleHide: () => {
        hide()
        noScroll.off()
      },
      onSubmitFail: () =>
        notif.error({
          title: 'Failed to send SMS verification code',
          message: 'Please check the form errors',
        }),
    }),
  ),

  withFormValidator({
    mobile: composeV(isRequired, isMobileNum)('Number'),
  }),
)
