import React from 'react'

import { formPropTypes } from 'common/auth/Shared/auth.helpers'
import { Button } from 'common/auth/Shared/sharedStyled'
import FormError from 'components.v2/FormError'
import Input from 'components.v2/Input'
import withContainer from './LoginFormContainer'
import styled from 'styled-components'
import get from 'lodash/get'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & input {
    & ::placeholder {
      color: var(--charcoal-2, #999);
    }

    font-size: 18px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    background: #f2f2f2;
    border-radius: 8px;
    border: none;
  }

  & > a {
    text-decoration: underline;
    color: var(--Orange-0, #f26722);
    cursor: pointer;
    text-align: right;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 28px;
  }
`

const SubmitButton = styled(Button)`
  background: #f26722;
  border-radius: 8px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  color: #ffffff;
  height: 52px;

  &:not(:focus) {
    border: none;
  }

  &:not([disabled]):hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:not([disabled]):active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

const CustomFormError = styled(FormError)`
  font-family: Montserrat;
  margin-top: 4px;
`

const LoginForm = ({
  fields,
  isLookingUp,
  isAuthenticating,
  handleSubmit,
  handleChange,
  handleBlur,
  className,
  actionsReversed,
  showForgotPassModal,
  email,
}) => (
  <Form onSubmit={handleSubmit} className={`${className} text-left`} noValidate>
    <div>
      <Input
        {...(email ? { autoFocus: true } : {})}
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.email.value || email}
        hasError={!!fields.email.error}
        type="email"
        name="email"
        placeholder="Email"
      />
      {get(fields, 'email.error') && (
        <CustomFormError>{get(fields, 'email.error')}</CustomFormError>
      )}
    </div>
    <div>
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.password.value}
        hasError={!!fields.password.error}
        type="password"
        name="password"
        placeholder="Password"
      />
      {get(fields, 'password.error') && (
        <CustomFormError>{get(fields, 'password.error')}</CustomFormError>
      )}
    </div>
    <a onClick={() => showForgotPassModal()} className="ml-auto">
      Forgot password?
    </a>
    <SubmitButton
      variant="primary"
      type="submit"
      className="w-full"
      disabled={isAuthenticating || isLookingUp}
      actionsReversed={actionsReversed}
      loading={isAuthenticating || isLookingUp}
    >
      {isAuthenticating ? 'Processing…' : 'Log in'}
    </SubmitButton>
  </Form>
)

LoginForm.propTypes = formPropTypes

export default withContainer(LoginForm)
