import React, { useEffect } from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import Input from 'components.v2/Input'
import FormError from 'components.v2/FormError'
import { Link, Button } from 'common/auth/Shared/sharedStyled'
import withContainer from './RegisterFormContainer'
import { formPropTypes } from 'common/auth/Shared/auth.helpers'
import { Title } from 'common/auth/Shared/AuthModalV2'
import Icon from 'components.v2/Icon/IconV2'
import { types } from 'store/modals'
import get from 'lodash/get'
import media from 'utils/media'
import PhoneInputWrapper from 'components.v2/PhoneInputWrapper'
import { PhoneInput } from 'react-international-phone'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & input {
    background: #f2f2f2;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    border: none;

    & ::placeholder {
      color: var(--charcoal-2, #999);
    }

    border-radius: 8px;
  }
`

const SubmitButton = styled(Button)`
  background: #f26722;
  border-radius: 8px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  height: 52px;

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`

const CustomFormError = styled(FormError)`
  font-family: Montserrat;
  margin-top: 4px;
`

const RegisterForm = ({
  fields,
  isLookingUp,
  handleSubmit,
  handleChange,
  handleBlur,
  className,
  // actionsReversed,
  email,
  mobile,
}) => {
  return (
    <Form onSubmit={handleSubmit} className={`${className} text-left`}>
      <div>
        <Input
          {...(email ? { autoFocus: true } : {})}
          onChange={handleChange}
          onBlur={handleBlur}
          value={fields.email.value || email}
          hasError={!!fields.email.error}
          type="email"
          name="email"
          placeholder="Email"
        />
        {get(fields, 'email.error') && (
          <CustomFormError>{get(fields, 'email.error')}</CustomFormError>
        )}
      </div>
      <PhoneInputWrapper>
        <PhoneInput
          {...(mobile ? { autoFocus: true } : {})}
          defaultCountry="au"
          value={fields.mobile.value || mobile}
          forceDialCode={true}
          onChange={phone =>
            handleChange({ target: { name: 'mobile', value: phone } })
          }
          onBlur={handleBlur}
          name="mobile"
          placeholder="Mobile number"
        />
        {get(fields, 'mobile.error') && (
          <CustomFormError>{get(fields, 'mobile.error')}</CustomFormError>
        )}
      </PhoneInputWrapper>
      <SubmitButton
        variant="primary"
        type="submit"
        disabled={isLookingUp}
        className="w-full"
      >
        {isLookingUp ? 'Processing…' : 'Create Acount'}
      </SubmitButton>
    </Form>
  )
}

RegisterForm.propTypes = {
  ...formPropTypes,
}

export default withContainer(RegisterForm)
