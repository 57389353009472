import React, { useState, useEffect, useRef } from 'react'
import { compose, withProps } from 'recompose'
import { login } from 'auth/modules/auth'
import CONFIG from 'app-config'
import notif from 'services/notification'
import { connect } from 'react-redux'
import shortid from 'shortid'
import SocialButton from './SocialButtonV2'
import noScroll from 'no-scroll'
import get from 'lodash/get'
import { show, types } from 'store/modals'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  & > button {
    width: 100%;
  }
`

const loadScript = src =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = err => reject(err)
    document.body.appendChild(script)
  })

const GoogleAuth = ({ onLoginSuccess, appId, provider, actionText, className, extended }) => {
  const googleButton = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'

    loadScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: appId,
          callback: onLoginSuccess,
        })
        google.accounts.id.renderButton(googleButton.current, {
          theme: 'outline',
          height: 50,
        })
        googleButton.current.style.display = 'none'
        setIsLoaded(true)
      })
      .catch(error => {
        console.error(error)
        setIsLoaded(false)
      })

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  const handleButtonClick = e => {
    e.preventDefault()
    const googleSignInButton = googleButton.current.querySelector(
      'div[role=button]',
    )

    if (googleSignInButton) googleSignInButton.click()
  }

  return (
    <ButtonContainer>
      <div className="googleSignIn" ref={googleButton} />
      <SocialButton
        actionText={actionText}
        provider={provider}
        triggerLogin={handleButtonClick}
        disabled={!isLoaded}
        className={className}
        extended={extended}
      />
    </ButtonContainer>
  )
}

export default compose(
  connect(null, { login, show }),
  withProps(
    ({
      login,
      show,
      provider = 'google',
      onGoogleSignIn = () => {},
      changeKey,
    }) => ({
      provider,
      variant: provider,
      version: 'v14.0',
      appId: {
        facebook: CONFIG.FACEBOOK_APP_ID,
        google: CONFIG.GOOGLE_CLIENT_ID,
      }[provider],
      onLoginSuccess: ({ credential }) => {
        const onAfterSuccess = () => {
          onGoogleSignIn()
          notif.success({ title: 'Login successful' })
          noScroll.off()
        }

        login({
          email: '',
          password: {
            google: `googlejwt:${credential}`,
          }[provider],
        })
          .then(onAfterSuccess)
          .catch(error => {
            const errorCode = get(error, 'response.data.status.code', 400)

            switch (errorCode) {
              case 110012:
                show(types.SMS_SEND_CODE, {
                  redirectFrom: types.LOGIN_V2,
                  auth: JSON.stringify({
                    email: '',
                    password: {
                      google: `googlejwt:${credential}`,
                    }[provider],
                  }),
                  onSuccess: onAfterSuccess,
                })
                break
              case 110013:
                show(types.SMS_VERIFY, {
                  redirectFrom: types.LOGIN_V2,
                  auth: JSON.stringify({
                    email: '',
                    password: {
                      google: `googlejwt:${credential}`,
                    }[provider],
                  }),
                  onSuccess: onAfterSuccess,
                })
                break
              default:
                notif.error({
                  title: 'Login failed',
                  message: 'please try again',
                  error,
                })
            }
          })
      },
      onLoginFailure: error => {
        notif.error({
          title: 'Authentication failed',
          message: 'please try again',
          error,
        })

        changeKey(shortid.generate())
      },
    }),
  ),
)(GoogleAuth)
