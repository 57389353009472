import client from 'client'
import flatten from 'lodash/flatten'
import values from 'lodash/values'
import get from 'lodash/get'
import set from 'lodash/fp/set'
import pick from 'lodash/fp/pick'
import cloneDeep from 'lodash/fp/cloneDeep'
import lowerCase from 'lodash/lowerCase'
import findKey from 'lodash/findKey'
import times from 'lodash/times'
import find from 'lodash/find'
import filter from 'lodash/filter'
import map from 'lodash/map'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import findIndex from 'lodash/findIndex'
import isEqual from 'lodash/isEqual'
import includes from 'lodash/includes'
import { show, types } from 'store/modals'
import axios from 'axios'
import shortid from 'shortid'
import Decimal from 'decimal.js'
import { v4 as uuidv4 } from 'uuid'
import { loadStripe } from '@stripe/stripe-js'

import { createPromiseAction } from 'utils/asyncActions'
import { formatMenu } from 'pages/vendor/modules/menu'
import { selectDeliverySuburbs } from 'pages/vendor/modules/info'
import { selectGuestCheckoutHasEnabled } from 'store/guest-checkout/selectors'
import { getPrice, CURRENCY_SYMBOL } from 'utils/currency'
import {
  selectHasSufficientPaymentSource,
  selectPaymentSourcesIsLoading,
  selectPreferredPaymentSource
} from 'store/payment'
import { createPaymentIntent } from 'api'
import getHistory from 'services/history'
import notif from 'services/notification'
import {
  DINE_IN,
  TAKEAWAY,
  DELIVERY,
  ERROR_ADDRESS_REQUIRED,
  SERVICE_TYPES,
  CHECKOUT_FLAG_SAYNOTOPLASTIC,
  NOTE_NO_CUTLERY_PREFIX,
  PAYMENT_GATEWAY_STRIPE,
  PAYMENT_GATEWAY_STRIPE_CHECKOUT,
  NOTE_CUSTOMER_PREFIX,
  NOTE_DINEIN_PREFIX,
  UNAVAILABLE,
  TEMP_UNAVAILABLE,
  TYPE_WHITELABEL_ORDER,
} from 'pages/order/const'
import {
  LOCAL_STORAGE_DINEIN_TABLE,
  LOCAL_STORAGE_DINEIN_TABLE_NOTIFICATION,
} from 'pages/vendor/const'
import config from 'app-config'
import { isWhiteLabel } from 'utils/whiteLabel'
import { FZ_CLICK_TO_PAY_HOSTED_PAYMENT } from 'store/payment/const'
import { getConfig } from 'contexts/config-context'

/**
 * Constants
 */
const CHECKOUT_ORDER = 'unicorn/order/CHECKOUT_ORDER'
const ADD_ORDER = 'unicorn/order/ADD_ORDER'
const ADD_FAVOURITES_TO_ORDER = 'unicorn/order/ADD_FAVOURITES_TO_ORDER'
const CHANGE_ORDER_QTY = 'unicorn/order/CHANGE_ORDER_QTY'
const UPSIZE_ORDER = 'unicorn/order/UPSIZE_ORDER'
const REMOVE_ORDER = 'unicorn/order/REMOVE_ORDER'
export const CHANGE_SERVICE_TYPE = 'unicorn/order/CHANGE_SERVICE_TYPE'
export const CLEAR_DELIVERY_INFORMATION =
  'unicorn/order/CLEAR_DELIVERY_INFORMATION'
export const CHANGE_DELIVERY_INFORMATION =
  'unicorn/order/CHANGE_DELIVERY_INFORMATION'
export const VALIDATE_DELIVERY_INFORMATION =
  'unicorn/order/VALIDATE_DELIVERY_INFORMATION'
export const UNVALIDATE_DELIVERY_INFORMATION =
  'unicorn/order/UNVALIDATE_DELIVERY_INFORMATION'
export const SET_NOTE = 'unicorn/order/SET_NOTE'
export const SET_SEATING_INFO = 'unicorn/order/SET_SEATING_INFO'
export const SET_NO_CUTLERY = 'unicorn/order/SET_NO_CUTLERY'
export const SET_IS_LOADING = 'unicorn/order/SET_IS_LOADING'
export const RELOAD_PLACED_ORDER = 'unicorn/order/RELOAD_PLACED_ORDER'
export const REORDER = 'unicorn/order/REORDER'

/**
 * Action Creators
 */
export const placeOrder = createPromiseAction('unicorn/order/PLACE_ORDER')
export const pullStatus = createPromiseAction('unicorn/order/PULL_STATUS')

const checkoutSrc = axios.CancelToken.source()

export const checkoutOrder = venueId => (dispatch, getState) => {
  checkoutSrc.cancel('Cancelled for newer request')

  const state = getState()
  const venueOrders = selectOrdersByVenueId(state, venueId)
  const items = ordersToItemsPayload(venueOrders)
  const serviceType = selectSelectedServiceType(state, venueId) || TAKEAWAY
  const { address, mobile } = selectDeliveryInfoByVenueId(state, venueId)

  dispatch({
    type: CHECKOUT_ORDER,
    meta: { venueId, reqId: shortid.generate() },
    payload: client()
      .get('/nonce')
      .then(({ data }) =>
        client().post('/orders/checkout', {
          cancelToken: checkoutSrc.token,
          nonce: data.nonces[0],
          venueId,
          serviceType,
          items,
          address,
          mobile,
        }),
      )
      .then(data => data.data)
      .catch(error => {
        if (
          get(error, 'response.data.status.flags', []).includes(
            ERROR_ADDRESS_REQUIRED,
          )
        ) {
          notif.error({
            message: 'Delivery address required.',
            error,
          })
        }

        dispatch(
          checkInvalidItems({
            error,
            venueOrders,
            venueId,
          }),
        )

        return get(error, 'response.data')
      }),
  })
}

export const checkInvalidItems = ({ error, venueOrders, venueId }) => (
  dispatch,
  getState,
) => {
  const getSerialisedOptionNameById = (orders, menuId, id) => {
    const menuItem = find(orders, item => item.id === menuId)

    if (isEmpty(menuItem)) {
      return null
    }
    const options = flatten(values(menuItem.optionGroups))
    const option = find(options, item =>
      find(get(item, 'options'), i => i.id === id),
    )
    if (isEmpty(option)) {
      return null
    }
    return get(
      find(get(option, 'options'), item => item.id === id),
      'name',
      '',
    )
  }

  if (
    find(
      get(error, 'response.data.invalidItems'),
      item =>
        item.status === UNAVAILABLE ||
        item.status === TEMP_UNAVAILABLE ||
        !!get(item, 'invalidMenuOptionIds'),
    )
  ) {
    const unavailableItems = map(
      filter(
        get(error, 'response.data.invalidItems'),
        item =>
          item.status === UNAVAILABLE ||
          item.status === TEMP_UNAVAILABLE ||
          get(item, 'invalidMenuOptionIds'),
      ),
      item => {
        if (get(item, 'invalidMenuOptionIds')) {
          const optionIds = get(
            item,
            'invalidMenuOptionIds',
          ).map(serialisedOptionId =>
            getSerialisedOptionNameById(
              venueOrders,
              item.id,
              serialisedOptionId,
            ),
          )

          return `${item.name} (${optionIds.join(', ')})`
        }
        return item.name
      },
    )

    const length = unavailableItems.length
    const lastItem = unavailableItems.slice(length - 1)

    let message = ''

    if (length === 1) {
      message = `${lastItem} is`
    } else {
      message = join(unavailableItems.slice(0, length - 1), ', ')
      message += ' and '
      message += lastItem
      message += ' are'
    }

    dispatch(
      show(types.UNAVAILABLE_ITEM, {
        message: `${message} no longer available. We've updated your cart to reflect this. Sorry about that!`,
        action: () => {
          dispatch({
            type: REMOVE_ORDER,
            payload: {
              items: get(error, 'response.data.invalidItems'),
              venueId,
            },
          })
        },
      }),
    )
  }
}

export const reloadOrderStatus = order => dispatch => {
  dispatch({ type: RELOAD_PLACED_ORDER, payload: { order } })
}

export const addOrder = (venueId, order, opts) => (dispatch, getState) => {
  dispatch({ type: ADD_ORDER, payload: { venueId, order } })

  const state = getState()
  const shouldCheckout = get(opts, 'checkout', true)
  const hasOrders = !!selectOrdersByVenueId(state, venueId).length

  if (shouldCheckout && hasOrders) {
    dispatch(checkoutOrder(venueId))
  }
}

export const reOrder = ({ venueId, orders }) => (dispatch, getState) => {
  dispatch({
    type: REORDER,
    payload: { venueId, orders },
  })
}

export const addFavouritesToOrder = (venueId, order, opts) => (
  dispatch,
  getState,
) => {
  const res = dispatch({
    type: ADD_FAVOURITES_TO_ORDER,
    payload: { venueId, order },
  })

  const state = getState()
  const shouldCheckout = get(opts, 'checkout', true)
  const hasOrders = !!selectOrdersByVenueId(state, venueId).length

  if (shouldCheckout && hasOrders) {
    dispatch(checkoutOrder(venueId))
  }
  return res
}

export const upsizeOrder = ({
  id,
  venueId,
  serialisedOptions,
  opts,
  index,
}) => (dispatch, getState) => {
  dispatch({
    type: UPSIZE_ORDER,
    payload: { orderId: id, serialisedOptions, venueId, index },
  })

  const state = getState()
  const shouldCheckout = get(opts, 'checkout', true)
  const hasOrders = !!selectOrdersByVenueId(state, venueId).length

  if (shouldCheckout && hasOrders) {
    dispatch(checkoutOrder(venueId))
  }
}

export const changeOrderQty = (
  { serialisedOptions, id },
  venueId,
  quantity,
  opts,
) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ORDER_QTY,
    payload: { orderId: id, serialisedOptions, venueId, quantity },
  })

  const state = getState()
  const shouldCheckout = get(opts, 'checkout', true)
  const hasOrders = !!selectOrdersByVenueId(state, venueId).length

  if (shouldCheckout && hasOrders) {
    dispatch(checkoutOrder(venueId))
  }
}

/**
 * Reducer
 */

const initialState = {
  orders: {},
  isSubmitting: false,
  isChecking: false,
  checkoutReqId: null,
}

// Blacklist all keys but 'orders'
export const persistBlacklist = Object.keys(initialState).filter(
  key => key !== 'orders',
)

const reducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case REMOVE_ORDER:
      const orders = filter(
        get(state, `orders.${payload.venueId}.orders`),
        order =>
          !find(
            payload.items,
            i =>
              i.id === order.id &&
              (i.status === UNAVAILABLE ||
                i.status === TEMP_UNAVAILABLE ||
                !!get(i, 'invalidMenuOptionIds')),
          ),
      )
      return {
        ...state,
        orders: {
          ...get(state, 'orders'),
          [payload.venueId]: {
            ...get(state, `orders.${payload.venueId}`),
            orders,
          },
        },
      }
    case REORDER:
      return updateVenueOrderInfoById(
        state,
        get(payload, 'venueId'),
        venueOrder => {
          return {
            ...venueOrder,
            orders: get(payload, 'orders'),
            venueId: get(payload, 'venueId'),
            serviceType: '',
          }
        },
      )
    case ADD_ORDER:
      return updateVenueOrderInfoById(state, payload.venueId, venueOrder => {
        const { selectedOptionIds, ...partialNewOrder } = payload.order
        const newOrder = {
          ...partialNewOrder,
          serialisedOptions: JSON.stringify(selectedOptionIds),
        }

        const oldOrderIndex = venueOrder.orders.findIndex(
          oldOrder =>
            oldOrder.serialisedOptions === newOrder.serialisedOptions &&
            oldOrder.id === newOrder.id,
        )

        return {
          ...venueOrder,
          orders:
            oldOrderIndex === -1
              ? [...venueOrder.orders, newOrder]
              : venueOrder.orders.map(order =>
                  order.serialisedOptions === newOrder.serialisedOptions
                    ? { ...order, quantity: order.quantity + newOrder.quantity }
                    : order,
                ),
        }
      })

    case ADD_FAVOURITES_TO_ORDER:
      return updateVenueOrderInfoById(state, payload.venueId, venueOrder => {
        return {
          ...venueOrder,
          orders: payload.order,
        }
      })

    case UPSIZE_ORDER: {
      const { serialisedOptions, orderId, venueId, index } = payload
      const venueOrder = state.orders[venueId]

      return {
        ...state,
        orders: {
          ...state.orders,
          [venueId]: {
            ...venueOrder,
            orders: venueOrder.orders.map(
              (order, orderIndex) =>
                order.id === orderId && index === orderIndex
                  ? {
                      ...order,
                      serialisedOptions: JSON.stringify(serialisedOptions),
                      unitPrice: reduce(
                        serialisedOptions,
                        (currentPrice, o) =>
                          currentPrice.plus(
                            reduce(
                              o,
                              (acc, i) => {
                                const selectedOption = flatten(
                                  values(order.optionGroups),
                                ).find(option =>
                                  option.options.find(j => j.id === i),
                                )
                                const currentOption = selectedOption.options.find(
                                  j => j.id === i,
                                )
                                if (currentOption)
                                  return acc + getPrice(currentOption.price)
                                return acc
                              },
                              0,
                            ),
                          ),
                        new Decimal(getPrice(order.price)),
                      ).toNumber(),
                    }
                  : order,
              [],
            ),
          },
        },
      }
    }

    case CHANGE_ORDER_QTY: {
      const { serialisedOptions, orderId, venueId, quantity } = payload
      const venueOrder = state.orders[venueId]

      return {
        ...state,
        orders: {
          ...state.orders,
          [venueId]: {
            ...venueOrder,
            orders: venueOrder.orders.reduce((orders, order) => {
              const areOrdersIdentical =
                order.serialisedOptions === serialisedOptions &&
                order.id === orderId

              if (!areOrdersIdentical) return [...orders, order]

              if (quantity > 0) return [...orders, { ...order, quantity }]

              return orders // payload.quantity === 0 thus no need to include it to orders anymore
            }, []),
          },
        },
      }
    }

    case CHANGE_SERVICE_TYPE:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          serviceType: payload.serviceType,
        }),
        isSubmitting: false,
      }

    case SET_NOTE:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          note: payload.note,
        }),
        isSubmitting: false,
      }

    case SET_SEATING_INFO:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          seatingInfo: payload.seatingInfo,
        }),
        isSubmitting: false,
      }

    case SET_NO_CUTLERY:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          noCutlery: payload.noCutlery,
        }),
        isSubmitting: false,
      }

    case SET_IS_LOADING:
      return {
        ...state,
        isChecking: payload.isLoading,
      }

    case CLEAR_DELIVERY_INFORMATION:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          shouldValidateDeliveryInfo: false,
          address: undefined,
          mobile: undefined,
        }),
        isSubmitting: false,
      }
    case VALIDATE_DELIVERY_INFORMATION:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          shouldValidateDeliveryInfo: true,
        }),
        isSubmitting: false,
      }
    case UNVALIDATE_DELIVERY_INFORMATION:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          shouldValidateDeliveryInfo: false,
        }),
        isSubmitting: false,
      }

    case CHANGE_DELIVERY_INFORMATION:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          address: payload.address,
          mobile: payload.mobile,
        }),
        isSubmitting: false,
      }

    case String(placeOrder):
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          note: payload.note,
          payment: payload.payment,
          serviceType: payload.serviceType,
          seatingInfo: payload.seatingInfo,
          orderPartyId: payload.orderPartyId,
          customerId: payload.customerId,
          address: payload.address,
          mobile: payload.mobile,
          error: null,
        }),
        isSubmitting: true,
      }

    case String(placeOrder.fulfilled):
      return {
        ...updateVenueOrderInfoById(
          state,
          payload.venueId,
          ({ placedOrders, ...venueOrderInfo }) => ({
            placedOrders: {
              ...placedOrders,
              ...(payload.orderId && {
                [payload.orderId]: {
                  ...venueOrderInfo,
                  isAnonymous: payload.isAnonymous,
                },
              }),
            },
            latestPlacedOrderId: payload.orderId && payload.orderId.toString(),
            address: undefined,
            mobile: undefined,
            ...(payload.orderId && { orders: [] }),
            ...(payload.orderId && { checkout: {} }),
            note: '',
            voucher: '',
            ...(payload.orderId ? {} : venueOrderInfo),
          }),
        ),
        isSubmitting: false,
      }

    case String(placeOrder.rejected):
      return {
        ...updateVenueOrderInfoById(state, meta.venueId, venueOrderInfo => ({
          ...venueOrderInfo,
          error: payload,
        })),
        isSubmitting: false,
      }

    case String(RELOAD_PLACED_ORDER):
      return {
        ...updateVenueOrderInfoById(
          state,
          get(payload, 'order.vendor.id'),
          ({ placedOrders, ...venueOrderInfo }) => ({
            placedOrders: {
              ...venueOrderInfo.placedOrders,
              [get(payload, 'order.id')]: {
                checkout: {
                  fees: [
                    {
                      type: 'servicefee',
                      name: 'Service Fee',
                      amount: `${CURRENCY_SYMBOL}${get(
                        payload,
                        'order.transactionFee',
                      )}`,
                    },
                  ],
                  //TODO guest checkout discounts
                  discounts: [],
                },
                orders: get(payload, 'order.items')
                  .reduce((prev, cur) => {
                    prev[get(cur, 'product.id').toString()] = get(
                      prev,
                      `${get(cur, 'product.id')}`,
                    )
                      ? Object.assign(get(prev, `${get(cur, 'product.id')}`), {
                          quantity: new Decimal(
                            get(prev, `${get(cur, 'product.id')}.quantity`),
                          )
                            .plus(1)
                            .toNumber(),
                        })
                      : {
                          id: get(cur, 'product.id'),
                          name:
                            get(cur, 'description') || get(cur, 'product.name'),
                          price: `${CURRENCY_SYMBOL}${get(cur, 'cost')}`,
                          unitPrice: new Decimal(get(cur, 'cost'))
                            .plus(
                              get(cur, 'options', []).reduce(
                                (p, c) =>
                                  new Decimal(p)
                                    .plus(get(c, 'price', 0))
                                    .toNumber(),
                                0,
                              ),
                            )
                            .toNumber(),
                          quantity: 1,
                          ...(get(cur, 'options') && {
                            optionGroups: {
                              normal: get(cur, 'options').reduce(
                                (a, b) => [
                                  ...a,
                                  ...[
                                    {
                                      name: get(b, 'option.optionGroup.name'),
                                      options: [
                                        {
                                          id: get(b, 'id'),
                                          name: get(b, 'option.value'),
                                        },
                                      ],
                                    },
                                  ],
                                ],
                                [],
                              ),
                            },
                          }),
                          serialisedOptions: JSON.stringify(
                            get(cur, 'options', []).reduce(
                              (c, d) => [...c, get(d, 'id')],
                              [],
                            ),
                          ),
                        }
                    return prev
                  }, [])
                  .reduce((prev, cur) => [...prev, ...[cur]], []),
                note: get(payload, 'order.customerNote', '')
                  .replace(`${NOTE_CUSTOMER_PREFIX}. `, '')
                  .replace(NOTE_CUSTOMER_PREFIX, ''),
                venueId: get(payload, 'order.vendor.id'),
                serviceType: get(payload, 'order.serviceType'),
                payment: {},
                seatingInfo: get(payload, 'order.internalNote', '')
                  .replace(`${NOTE_DINEIN_PREFIX}. `, '')
                  .replace(NOTE_DINEIN_PREFIX, ''),
                orderPartyId: null,
                customerId: null,
                error: null,
                isRefetched: true,
                status: [get(payload, 'order.status')],
                etaInMinutes: get(payload, 'order.eta'),
                acceptedAt: get(payload, 'order.acceptedAt'),
                isAnonymous: get(payload, 'order.isAnonymous'),
                orderId: get(payload, 'order.id'),
                ...(get(
                  placedOrders,
                  `${get(payload, 'order.id')}.members`,
                ) && {
                  members: get(
                    placedOrders,
                    `${get(payload, 'order.id')}.members`,
                  ),
                }),
              },
            },
            latestPlacedOrderId: get(payload, 'order.id', '').toString(),
            address: undefined,
            mobile: undefined,
            orders: [],
            checkout: {},
            note: '',
          }),
        ),
        isSubmitting: false,
      }

    case `${CHECKOUT_ORDER}_PENDING`:
      return {
        ...updateVenueOrderInfoById(state, meta.venueId, { checkout: {} }),
        isChecking: true,
        checkoutReqId: meta.reqId,
      }

    case `${CHECKOUT_ORDER}_FULFILLED`:
      return meta.reqId === state.checkoutReqId
        ? {
            ...updateVenueOrderInfoById(state, meta.venueId, {
              checkout: payload,
            }),
            isChecking: false,
          }
        : state

    case `${CHECKOUT_ORDER}_REJECTED`:
      return { ...state, isChecking: false }

    case String(pullStatus):
      return updatePlacedOrderById(state, payload, {
        error: null,
      })

    case String(pullStatus.fulfilled):
      return updatePlacedOrderById(
        state,
        payload.orderId,
        pick(
          [
            'status',
            'etaInMinutes',
            'acceptedAt',
            'reason',
            'paymentErrorData',
            'members',
          ],
          payload,
        ),
      )

    case String(pullStatus.rejected):
      return updatePlacedOrderById(state, payload.orderId, {
        error: payload.error,
      })

    default:
      return state
  }
}

/**
 * Selectors
 */

export const selectOrderInfoByVenueId = (state, venueId) => {
  const orderInfo = get(state, `order.orders.${venueId}`)
  return orderInfo && { ...orderInfo, venueId }
}

export const selectIsChecking = state => state.order.isChecking

export const selectOrdersByVenueId = (state, venueId) =>
  get(state, `order.orders.${venueId}.orders`, [])

export const selectDiscountsByVenueId = (state, venueId) =>
  get(state, `order.orders.${venueId}.checkout.discounts`, [])

export const selectFeesByVenueId = (state, venueId) =>
  get(state, `order.orders.${venueId}.checkout.fees`, [])

export const selectNoteByVenueId = (state, venueId) =>
  get(state, `order.orders.${venueId}.note`, [])

export const selectSeatingInfoByVenueId = (state, venueId) =>
  get(state, `order.orders.${venueId}.seatingInfo`, '')

export const selectNoCutleryByVenueId = (state, venueId) =>
  get(state, `order.orders.${venueId}.noCutlery`, 0)

export const selectDeliveryInfoByVenueId = (state, venueId) => {
  const orderInfo = cloneDeep(selectOrderInfoByVenueId(state, venueId))
  const serviceType = get(orderInfo, 'serviceType')

  if (serviceType === DELIVERY) {
    return {
      shouldValidate: orderInfo.shouldValidateDeliveryInfo,
      mobile: orderInfo.mobile,
      address: orderInfo.address,
    }
  }

  return {}
}

export const selectInvalidDeliveryInfoByVenueId = (state, venueId) => {
  const deliveryInfo = selectDeliveryInfoByVenueId(state, venueId)
  const fields = [
    'address.address1',
    'address.address2',
    'address.postcode',
    'address.suburb',
    'address.state',
    'mobile',
  ]

  const invalidFields = []
  const emptyFields = fields.filter(
    field => get(deliveryInfo, field, '').trim().length === 0,
  )

  const suburb = get(deliveryInfo, 'address.suburb', '')
  const suburbs = selectDeliverySuburbs(state, venueId)

  if (
    suburbs.length &&
    !suburbs.map(lowerCase).includes(suburb.toLowerCase())
  ) {
    invalidFields.push('address.suburb')
  }

  return emptyFields.concat(invalidFields)
}

export const selectHasValidDeliveryInfoByVenueId = (state, venueId) =>
  selectInvalidDeliveryInfoByVenueId(state, venueId).length === 0

export const selectSelectedServiceType = (state, venueId) => {
  return get(selectOrderInfoByVenueId(state, venueId), 'serviceType')
}

export const selectIsSubmitting = state => state.order.isSubmitting

export const selectVenueIdByOrderId = (state, orderId) =>
  findKey(
    state.order.orders,
    venueOrdersState => venueOrdersState.placedOrders[orderId],
  )

export const selectTotalByVenueId = (state, venueId) => {
  const selectedOrders = selectOrdersByVenueId(state, venueId)
  const discounts = selectDiscountsByVenueId(state, venueId)
  const fees = selectFeesByVenueId(state, venueId)

  const ordersTotal = ordersToTotal(selectedOrders)
  const discountTotal = discountToTotal(discounts)
  const feesTotal = feesToTotal(fees)

  return new Decimal(ordersTotal)
    .plus(discountTotal)
    .plus(feesTotal)
    .toNumber()
}

export const selectPlacedOrderById = (state, orderId) => {
  const venueId = findKey(
    state.order.orders,
    venueOrderInfo => venueOrderInfo.placedOrders[orderId],
  )
  const existingPlacedOrder =
    venueId && state.order.orders[venueId].placedOrders[orderId]
  return existingPlacedOrder && { ...existingPlacedOrder, venueId, orderId }
}

export const selectPlacedOrderMultiById = (state, cartId) => {
  return get(state, `multiVendorOrder.placedOrders.${cartId}`, {})
}

// can select order using either placed order id or venue id
export const smartSelectOrderById = (state, id) =>
  selectPlacedOrderById(state, id) || selectOrderInfoByVenueId(state, id)

export const smartSelectMultiOrderById = (state, id) => {
  const value = selectPlacedOrderMultiById(state, id)
  return value
}

export const selectCanPlaceOrder = (state, venueId, priceToPay) => {
  const hasOrder = selectOrdersByVenueId(state, venueId).length
  const isGuestCheckout = selectGuestCheckoutHasEnabled(state)

  const isCheckingOut = selectIsChecking(state)
  const hasCheckoutError = get(
    state,
    `order.orders.${venueId}.checkout.status.flags`,
    [],
  )
    .filter(flag => (isGuestCheckout ? flag !== 'verificationRequired' : flag))
    .filter(flag => flag !== 'guestCheckoutEnabled')
    .filter(flag => flag !== 'paymentRequired')
    .filter(flag => flag !== 'noWasteEnabled').length

  const hasCheckoutRecalculateError = !get(
    state,
    `order.orders.${venueId}.checkout`,
    false,
  )

  const isLoadingPaymentSource = selectPaymentSourcesIsLoading(state)
  const hasSufficientPaymentSource = !isGuestCheckout
    ? selectHasSufficientPaymentSource(state, priceToPay)
    : true

  const s3Config = getConfig()
  const isClickToPayEnabled = get(s3Config,'features.payment.click_to_pay.enabled')

  const isLoading = !!(isCheckingOut || isLoadingPaymentSource)
  const paymentSource = selectPreferredPaymentSource(state, priceToPay)
  const canPlaceOrder = !!(
    !isLoading &&
    !hasCheckoutError &&
    !hasCheckoutRecalculateError &&
    hasOrder &&
    hasSufficientPaymentSource &&
    !(!isClickToPayEnabled && get(paymentSource,'card.type') === FZ_CLICK_TO_PAY_HOSTED_PAYMENT)
  )

  return {
    isLoading,
    value: canPlaceOrder,
  }
}

export const selectPlacedOrderByOrderId = (state, orderId) => {
  const ordersByVenueId = state.order.orders
  const venueId = selectVenueIdByOrderId(state, orderId)
  return (
    venueId && { venueId, ...ordersByVenueId[venueId].placedOrders[orderId] }
  )
}

export const selectSayNoToPlastic = (state, venueId) => {
  return get(
    state,
    `order.orders.${venueId}.checkout.status.flags`,
    [],
  ).includes(CHECKOUT_FLAG_SAYNOTOPLASTIC)
}

export const selectGuestCheckout = (state, venueId) => {
  return get(state, `vendor.info.info.property.guestCheckoutEnabled`, false)
}

/**
 * Helpers
 */

export const ordersToItemsPayload = orders =>
  flatten(
    orders.map(x =>
      times(x.quantity, () => ({
        id: x.id,
        options: flatten(JSON.parse(x.serialisedOptions)),
      })),
    ),
  )

export const orderToTotalUnitPrice = order =>
  new Decimal(order.unitPrice).mul(order.quantity).toNumber()
export const ordersToQty = xs =>
  xs.reduce((acc, x) => acc.plus(x.quantity), new Decimal(0)).toNumber()
export const ordersToTotal = xs =>
  xs
    .reduce((acc, x) => acc.plus(orderToTotalUnitPrice(x)), new Decimal(0))
    .toNumber()

export const discountToTotal = xs => {
  if (Array.isArray(xs)) {
    return xs
      .reduce((acc, x) => acc.plus(getPrice(x.amount)), new Decimal(0))
      .toNumber()
  }
  return 0
}

export const feesToTotal = discountToTotal

export const orderToCustomisationText = order => {
  const selectedOptIds = flatten(JSON.parse(order.serialisedOptions))

  return (
    formatMenu(order)
      // flatten menu[].options into one array
      .reduce(
        (opts, optObj) => [
          ...opts,
          // keep the name of optGroup in each option
          ...(optObj.options || []).map(opt => ({
            ...opt,
            parent: optObj.name,
          })),
        ],
        [],
      )
      // get name of all options that is selected but isn't default
      .reduce(
        (customNames, opt) =>
          selectedOptIds.includes(opt.id)
            ? [...customNames, `${opt.parent} ${opt.name}`]
            : customNames,
        [],
      )
      .join(', ')
  )
}

const orderSubmitV2 = async (authorized, placeOrder, params, ownProps) => {
  return (
    authorized &&
    (await placeOrder(params)
      .then(async ({ orderId, checkout_url }) => {
        localStorage.removeItem(
          `${LOCAL_STORAGE_DINEIN_TABLE}_${params.venueId}`,
        )
        localStorage.removeItem(
          `${LOCAL_STORAGE_DINEIN_TABLE_NOTIFICATION}_${params.venueId}_${params.seatingInfo}`,
        )

        if (
          (get(params, 'payment.type') === PAYMENT_GATEWAY_STRIPE_CHECKOUT ||
          get(params, 'payment.type') === FZ_CLICK_TO_PAY_HOSTED_PAYMENT) &&
          checkout_url
        ) {
          await ownProps.unsetGuestCheckoutEmail()
          window.location.href = checkout_url
          return
        }

        getHistory().replace(`/order/${orderId}/status`)
      })
      .catch(err => {
        ownProps.checkInvalidItems({
          venueId: params.venueId,
          venueOrders: params,
          error: err,
        })
      })
      .finally(async () => {
        await ownProps.handleIsLoadingChange(false)
      }))
  )
}

const orderSubmitV3 = async (placeOrder, params, ownProps) => {

  return await placeOrder(params)
    .then(
      async ({
        orderPartyId,
        orderId,
        venueId,
        pi_client_secret,
        checkout_url,
      }) => {
        localStorage.removeItem(
          `${LOCAL_STORAGE_DINEIN_TABLE}_${params.venueId}`,
        )
        localStorage.removeItem(
          `${LOCAL_STORAGE_DINEIN_TABLE_NOTIFICATION}_${params.venueId}_${params.seatingInfo}`,
        )

        if (
          (get(params, 'payment.type') === FZ_CLICK_TO_PAY_HOSTED_PAYMENT ||
          get(params, 'payment.type') === PAYMENT_GATEWAY_STRIPE_CHECKOUT) &&
          checkout_url
        ) {
          window.location.href = checkout_url
          await ownProps.unsetGuestCheckoutEmail()
          return
        }

        if (!checkout_url && (orderId || (orderPartyId && venueId))) {
          orderPartyId && venueId
            ? getHistory().replace(`/order/party/${venueId}/status`)
            : getHistory().replace(`/order/${orderId}/status`)
        }

        if (get(params, 'payment.type') === PAYMENT_GATEWAY_STRIPE) {
          const paymentConfig = await ownProps.fetchPaymentConfig()
          const stripe = await loadStripe(
            get(paymentConfig, 'value.stripe.publishableKey'),
          )
  
          const confirmRes = await stripe.confirmCardPayment(pi_client_secret)
  
          if (confirmRes.error && !orderPartyId) {
            notif.error({
              title: 'Error',
              message: get(confirmRes, 'error.message'),
            })
          }
        }
      },
    )
    .catch(err => {
      ownProps.checkInvalidItems({
        venueId: params.venueId,
        venueOrders: params,
        error: err,
      })
    })
    .finally(async () => {
      await ownProps.handleIsLoadingChange(false)
    })
}

export const convertToOrderListPayload = (state, order) => {
  const menu = flatten(
    map(get(state, 'vendor.menu.menus', []), menu => menu.items),
  )

  const getItemFromMenu = item => find(menu, i => i.id === item.id)

  return map(
    reduce(
      get(order, 'items'),
      (acc, item) => {
        const index = findIndex(
          acc,
          i => i.id === item.id && isEqual(i.options, item.options),
        )
        if (index === -1) {
          return [
            ...acc,
            {
              ...item,
              quantity: 1,
            },
          ]
        } else {
          acc[index].quantity++
          return acc
        }
      },
      [],
    ),
    ({ options, ...item }) => {
      const menuItem = getItemFromMenu(item)
      const optionGroups = flatten(values(get(menuItem, 'optionGroups')))
      const serialisedOptions = map(optionGroups, option => {
        const selectedOptions = filter(option.options, opt =>
          includes(options, opt.id),
        )
        if (!isEmpty(selectedOptions)) {
          return map(selectedOptions, ({ id }) => id)
        } else {
          return map(
            filter(option.options, opt => opt.isDefault),
            ({ id }) => id,
          )
        }
      })

      return {
        serialisedOptions: JSON.stringify(serialisedOptions),
        unitPrice: getPrice(get(menuItem, 'price')),
        quantity: get(item, 'quantity'),
        ...menuItem,
      }
    },
  )
}

export const handleStripePlaceOrder = async (state, ownProps, placeOrder) => {
  if (!state.canPlaceOrder.value) {
    return
  }

  const isGuestCheckout = get(state, 'isGuestCheckout')
  const guestCheckoutCustomer = get(state, 'guestCheckoutCustomer')
  const serviceTypes = get(state, 'serviceTypes', SERVICE_TYPES)
  const serviceType = serviceTypes.includes(state.serviceType)
    ? state.serviceType
    : serviceTypes[0] || TAKEAWAY

  if (serviceType === DELIVERY && !state.hasValidDeliveryInfo) {
    // Verify the delivery address
    ownProps.validateDeliveryInfo()

    // If it still doesn't have a valid info
    if (!state.hasValidDeliveryInfo) {
      ownProps.notify({
        type: 'error',
        message:
          'There is something wrong with your delivery address. Please check and try again.',
      })
    }

    return
  }

  if (serviceType === DINE_IN && !state.seatingInfo) {
    await notif.error({
      title: 'Seating info required.',
      message: 'Please fill the seating info.',
    })
    return
  }

  await ownProps.handleIsLoadingChange(true)
  let intentId = null
  let authorized = true
  const credits = getPrice(get(state, 'sources.credit.available', ''))
  const transactionTotal = new Decimal(state.priceToPay)
    .minus(credits)
    .toNumber()

  if (
    get(state, 'sources.card.type') === PAYMENT_GATEWAY_STRIPE &&
    transactionTotal > 0 &&
    config.ORDER_SUBMIT_VERSION === '2'
  ) {
    const intent = await createPaymentIntent({
      charge: transactionTotal,
      idempotent_key: uuidv4(),
    })

    if (intent.data.requires_action) {
      const paymentConfig = await ownProps.fetchPaymentConfig()
      const stripe = await loadStripe(
        get(paymentConfig, 'value.stripe.publishableKey'),
      )
      await stripe
        .confirmCardPayment(intent.data.pi_client_secret)
        .then(function(confirm) {
          if (confirm.error) {
            notif.error({
              title: 'Error',
              message: get(confirm, 'error.message'),
            })

            authorized = false
          } else {
            intentId = confirm.paymentIntent.id
          }
        })
    } else {
      intentId = intent.data.pi_id
    }
  }

  // SEND ORDER
  const params = {
    venueId: ownProps.match.params.id,
    note: state.selectedNoCutlery
      ? NOTE_NO_CUTLERY_PREFIX.concat(' ').concat(state.note)
      : state.note,
    // prettier-ignore
    ...(intentId && config.ORDER_SUBMIT_VERSION === '2' && { payment: {
      type: 'stripe',
      details: { token: intentId },
    }}),
    ...(get(state, 'sources.card.type') === PAYMENT_GATEWAY_STRIPE &&
      config.ORDER_SUBMIT_VERSION === '3' && {
        payment: {
          type: get(state, 'sources.card.type'),
        },
      }),
    ...(isGuestCheckout &&
      guestCheckoutCustomer && {
        payment: { type: PAYMENT_GATEWAY_STRIPE_CHECKOUT },
      }),
    serviceType,
    seatingInfo: state.seatingInfo,
    orderPartyId: state.orderPartyId,
    customerId: state.customerId,
    address: state.deliveryInfo.address,
    mobile: state.deliveryInfo.mobile,
    ...(isGuestCheckout &&
      guestCheckoutCustomer && {
        customer: { ...guestCheckoutCustomer },
      }),
    ...(isWhiteLabel() && {
      orderType: TYPE_WHITELABEL_ORDER,
    }),
  }

  if (config.ORDER_SUBMIT_VERSION === '2') {
    orderSubmitV2(authorized, placeOrder, params, ownProps)
  } else if (config.ORDER_SUBMIT_VERSION === '3') {
    orderSubmitV3(placeOrder, params, ownProps)
  }
}

export const handleClickToPayPlaceOrder = async (state, ownProps, placeOrder) => {
  if (!state.canPlaceOrder.value) {
    return
  }

  const isGuestCheckout = get(state, 'isGuestCheckout')
  const guestCheckoutCustomer = get(state, 'guestCheckoutCustomer')
  const serviceTypes = get(state, 'serviceTypes', SERVICE_TYPES)
  const serviceType = serviceTypes.includes(state.serviceType)
    ? state.serviceType
    : serviceTypes[0] || TAKEAWAY

  if (serviceType === DELIVERY && !state.hasValidDeliveryInfo) {
    // Verify the delivery address
    ownProps.validateDeliveryInfo()

    // If it still doesn't have a valid info
    if (!state.hasValidDeliveryInfo) {
      ownProps.notify({
        type: 'error',
        message:
          'There is something wrong with your delivery address. Please check and try again.',
      })
    }

    return
  }

  if (serviceType === DINE_IN && !state.seatingInfo) {
    await notif.error({
      title: 'Seating info required.',
      message: 'Please fill the seating info.',
    })
    return
  }

  await ownProps.handleIsLoadingChange(true)

  // SEND ORDER
  const params = {
    venueId: ownProps.match.params.id,
    note: state.selectedNoCutlery
      ? NOTE_NO_CUTLERY_PREFIX.concat(' ').concat(state.note)
      : state.note,
    payment: {
      type: FZ_CLICK_TO_PAY_HOSTED_PAYMENT
    },
    serviceType,
    seatingInfo: state.seatingInfo,
    orderPartyId: state.orderPartyId,
    customerId: state.customerId,
    address: state.deliveryInfo.address,
    mobile: state.deliveryInfo.mobile,
    ...(isGuestCheckout &&
      guestCheckoutCustomer && {
        customer: { ...guestCheckoutCustomer },
      }),
    ...(isWhiteLabel() && {
      orderType: TYPE_WHITELABEL_ORDER,
    }),
  }

  if (config.ORDER_SUBMIT_VERSION === '2') {
    orderSubmitV2(authorized, placeOrder, params, ownProps)
  } else if (config.ORDER_SUBMIT_VERSION === '3') {
    orderSubmitV3(placeOrder, params, ownProps)
  }
}

/**
 * Internal Functions
 */

const initialVenueOrder = {
  checkout: {},
  orders: [],
  note: '',
  placedOrders: {},
}

const updateVenueOrderInfoById = (orderState, venueId, updater) => {
  const existingVenueOrderInfo =
    selectOrderInfoByVenueId({ order: orderState }, venueId) ||
    initialVenueOrder

  return set(
    `orders.${venueId}`,
    applyUpdater(existingVenueOrderInfo, updater),
    orderState,
  )
}

const updatePlacedOrderById = (orderState, orderId, updater) => {
  const existingPlacedOrder =
    selectPlacedOrderById({ order: orderState }, orderId) || {}

  return set(
    `orders.${existingPlacedOrder.venueId}.placedOrders.${orderId}`,
    applyUpdater(existingPlacedOrder, updater),
    orderState,
  )
}

export const applyUpdater = (subj, updater) =>
  typeof updater === 'function'
    ? updater(subj)
    : Array.isArray(updater)
    ? [...subj, ...updater]
    : typeof updater === 'object'
    ? { ...subj, ...updater }
    : updater

export default reducer
