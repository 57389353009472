import React, { useContext, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PT from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { show, hide, types } from 'store/modals'
import LoginForm from 'common/auth/LoginFormV2'
import StylableModal from 'components.v2/Modal/StylableModal'
import { RegisterFormV2 as RegisterForm } from 'common/auth/RegisterForm'
import titleCase from 'utils/titleCase'
import events from 'const/analytics-events'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'
import { selectGuestCheckoutHasEnabled } from 'store/guest-checkout/selectors'
import { ConfigContext } from 'contexts/config-context'
import Icon from 'components.v2/Icon/IconV2'
import GoogleLogin from './GoogleLoginV2'
import SocialButton from './SocialButtonV2'
import media from 'utils/media'
import BaseButton from 'components.v2/Button'
import noScroll from 'no-scroll'
import { fetchProfile } from 'api'

const SUCCESS = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  REGISTER_FORM: 'register-form',
  LOGIN_FORM: 'login-form',
}

const withContainer = connect(null, { show, hide })

const withGuestOrder = connect(state => {
  return {
    isGuestCheckout: selectGuestCheckoutHasEnabled(state),
  }
})

const ModalCmpt = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;
    max-width: 391px;
    width: ${p => (p.small ? 'calc(100vw - 60px)' : 'calc(100vw - 30px)')};
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
    width: 100%;
    overflow-y: hidden;
    ${media.phone`
      width: calc(100% - 30px);
    `}
  }

  &__content.LOGIN_V2,
  &__content.REGISTER_V2 {
    ${media.phone`
      .LOGIN_V2,
      .REGISTER_V2 {
        height: 100%;
        border-radius: 0px;
      }
    `}

    ${media.phone`
      width: 100%;
      height: 100%;
      max-width: 100%;
      border-radius: 0px;
    `}
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const GuestCheckout = compose(withGuestOrder)(
  ({ hasGuestCheckoutEnabled, show }) => {
    const config = useContext(ConfigContext)

    const renderGuestCheckoutUi = () =>
      hasGuestCheckoutEnabled && get(config, 'features.guest_checkout.enabled')

    const handleShowGuestCheckout = () => {
      show(types.GUEST_V2, {
        ...(hasGuestCheckoutEnabled && {
          hasGuestCheckoutEnabled: hasGuestCheckoutEnabled,
        }),
      })
    }

    return (
      <Fragment>
        {renderGuestCheckoutUi() ? (
          <GuestButton className="w-full" onClick={handleShowGuestCheckout}>
            Continue as a guest
          </GuestButton>
        ) : (
          <div />
        )}
      </Fragment>
    )
  },
)

class AuthModal extends React.Component {
  handle2FA = async () => {
    const { show } = this.props

    const { data } = await fetchProfile()
    const flags = data.customer.flags
    const mobile = data.customer.mobile

    if (!flags?.includes('MOBILE_VERIFIED')) {
      show(types.SMS_SEND_CODE, {
        redirectFrom: 'HOMEPAGE',
        auth: JSON.stringify({ mobile }),
      })
    }
  }

  handleSuccess = authType => () => {
    const { hide, trackEvent, handleSuccess } = this.props

    noScroll.off()
    hide()

    if (authType === SUCCESS.FACEBOOK) {
      trackEvent(events['SIGNUP_CLICK_FACEBOOK'])
    }

    if (authType === SUCCESS.GOOGLE) {
      trackEvent(events['SIGNUP_CLICK_GOOGLE'])
    }

    if (authType === SUCCESS.REGISTER_FORM) {
      trackEvent(events['SIGNUP_CLICK_EMAIL'])
    }

    handleSuccess && handleSuccess()

    if (
      [SUCCESS.FACEBOOK, SUCCESS.GOOGLE, SUCCESS.LOGIN_FORM].includes(authType)
    )
      this.handle2FA()
  }

  handleRequestCLose = () => {
    if (this.props.disableRequestCLose !== true) {
      this.props.hide()
      noScroll.off()
    }
  }

  render() {
    const {
      type,
      show,
      hide,
      email,
      mobile,
      hasGuestCheckoutEnabled,
    } = this.props

    noScroll.on()

    return (
      <ModalCmpt
        isOpen
        contentLabel={titleCase(type)}
        onRequestClose={this.handleRequestCLose}
        prependClassName={type}
      >
        <Container className={type}>
          <BackButton onClick={hide}>
            <Icon src="CloseIcon.svg" />
          </BackButton>
          <Title>
            <div className="w-full">
              {{
                [types.REGISTER_V2]: () => <>Let's create your account</>,
                [types.LOGIN_V2]: () => <>Log in</>,
              }[type]()}
            </div>
          </Title>
          {{
            [types.REGISTER_V2]: () => (
              <RegisterForm
                onSuccess={this.handleSuccess(SUCCESS.REGISTER_FORM)}
                className="w-full"
                email={email}
                mobile={mobile}
                show={show}
              />
            ),
            [types.LOGIN_V2]: () => (
              <LoginForm
                show={show}
                onSuccess={this.handleSuccess(SUCCESS.LOGIN_FORM)}
                className="w-full"
                email={email}
              />
            ),
          }[type]()}
          <Divider>
            <span>or</span>
          </Divider>
          <ButtonSection>
            <SocialButton
              onSuccess={this.handleSuccess(SUCCESS.FACEBOOK)}
              provider="facebook"
            />
            <GoogleLogin onGoogleSignIn={this.handleSuccess(SUCCESS.GOOGLE)} />
            <ButtonIcon>
              <Icon src="GuestApple.svg" />
            </ButtonIcon>
          </ButtonSection>
          <Helper>
            {{
              [types.REGISTER_V2]: () => (
                <>
                  Already have an account?{' '}
                  <a onClick={() => show(types.LOGIN_V2, { hasGuestCheckoutEnabled })}>Log in</a>
                </>
              ),
              [types.LOGIN_V2]: () => (
                <>
                  New to Hey You?{' '}
                  <a onClick={() => show(types.REGISTER_V2, { hasGuestCheckoutEnabled })}>
                    Create an Account
                  </a>
                </>
              ),
            }[type]()}
          </Helper>
          {hasGuestCheckoutEnabled && (
            <GuestCheckout hasGuestCheckoutEnabled show={show} />
          )}
        </Container>
      </ModalCmpt>
    )
  }
}

AuthModal.propTypes = {
  type: PT.oneOf(['LOGIN_V2', 'REGISTER_V2', 'AUTH', 'GUEST_V2']),
  disableRequestCLose: PT.bool,
  hasGuestCheckoutEnabled: PT.bool,
  email: PT.string,
  mobile: PT.string,
}

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
  border-radius: 12px;

  padding: 32px;

  overflow-y: auto;

  ${media.phone`
    width: 100%;
  `}

  .guest {
    ${media.phone`
      width: 100vw;
    `}
  }
`

export const Title = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  margin-bottom: 24px;
`

export const BackButton = styled.div`
  margin-bottom: 32px;

  &:active {
    border: none;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 16px 0;

  & > span {
    background: #ffffff;
    color: var(--Grey-1, #666);
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    overflow: hidden;
    width: 52px;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    top: calc(50% - 0.5px);
  }
`

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 24px;

  & > * {
    flex: 1;
  }
`

export const ButtonIcon = styled.button`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background: linear-gradient(0deg, #fafafa, #fafafa);
  height: 52px;

  & > div {
    height: 24px;
  }

  &:active {
    background: #e6e6e6;
  }

  &:hover {
    background: rgb(0, 0, 0, 0.05);
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const Helper = styled.p`
  color: var(--Gray-1, #666666);
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;

  & > a {
    text-decoration: underline;
    color: var(--Orange-0, #f26722);
    cursor: pointer;
  }
`

const GuestButton = styled(BaseButton)`
  background: #f26722;
  border-radius: 8px;
  height: 56px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  ${media.phone`
    font-size: 14px;
    line-height: 20px;
  `}

  color: #ffffff;

  margin-top: 20px;

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

export default compose(withContainer, withAnalyticsHandler)(AuthModal)
