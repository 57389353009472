import React from 'react'
import { connect } from 'react-redux'

import { selectVisibleModal, selectData, types } from 'store/modals'
import Auth from 'common/auth/Shared/AuthModal'
import AuthV2 from 'common/auth/Shared/AuthModalV2'
import RegisterV2LastStep from 'common/auth/RegisterForm/v2LastStep'
import ForgotPassword, { ForgotPasswordV2 } from 'common/auth/ForgotPassword'
import VerifyModal from 'common/smsv/VerifyModal'
import SendCodeModal from 'common/smsv/SendCodeModal'
import SearchModal from 'common/SearchOverlay'
import PartyModal from 'common/party'
import VenueModal from 'common/VenueModal'
import GuestModal, { GuestModalV2 } from 'common/GuestModal'
import GenericModal from 'common/GenericModal'
import UnavailableItemModal from 'common/UnavailableItemModal'
import PaymentSourceModal from 'common/PaymentSourceModal'
import OrderPartyModal from 'pages/vendor/OrderParty/OrderPartyModal'
import OrderPartyGenericModal from 'pages/party/Modal'
import MemberLeftBehindModal from 'pages/party/MemberLeftBehindModal'
import ActiveOrderPartyModal from 'pages/vendor/OrderParty/ActiveOrderPartyModal'
import OrderPartyAlert from 'pages/party/OrderPartyAlert'
import { EmailUpdatedModal } from 'pages/account/main/EditProfile/EditEmail'
import CardAdderModal from 'containers/CardAdder/CardAdderModal'

const withContainer = connect(state => ({
  visibleModal: selectVisibleModal(state),
  data: selectData(state),
}))

const ModalSwitch = ({ visibleModal, data }) => {
  switch (visibleModal) {
    case types.LOGIN:
    case types.REGISTER:
      return <Auth type={visibleModal} {...data} />
    case types.LOGIN_V2:
    case types.REGISTER_V2:
    case types.AUTH:
      return <AuthV2 type={visibleModal} {...data} />
    case types.REGISTER_V2_LAST_STEP:
      return <RegisterV2LastStep type={visibleModal} {...data} />
    case types.GUEST:
      return <GuestModal type={visibleModal} {...data} />
    case types.GUEST_V2:
      return <GuestModalV2 type={visibleModal} {...data} />
    case types.FORGOT:
    case types.FORGOT_SENT:
      return <ForgotPassword type={visibleModal} {...data} />
    case types.FORGOT_V2:
    case types.FORGOT_V2_SENT:
      return <ForgotPasswordV2 type={visibleModal} {...data} />
    case types.SMS_VERIFY:
      return <VerifyModal {...data} />
    case types.SMS_SEND_CODE:
      return <SendCodeModal {...data} />
    case types.SEARCH:
      return <SearchModal />
    case types.PARTY_NOTIF:
      return <PartyModal {...data} />
    case types.PARTY_INVITE:
      return <OrderPartyModal {...data} />
    case types.SERVING_TO_TABLE:
      return <VenueModal data={data} />
    case types.UNAVAILABLE_ITEM:
      return <UnavailableItemModal data={data} />
    case types.ORDER_PARTY_GENERIC_MODAL:
      return <OrderPartyGenericModal {...data} />
    case types.ORDER_PARTY_MEMBER_LEFT_BEHIND:
      return <MemberLeftBehindModal {...data} />
    case types.HAS_ACTIVE_ORDER_PARTY_MODAL:
      return <ActiveOrderPartyModal {...data} />
    case types.ORDER_PARTY_ALERT:
      return <OrderPartyAlert {...data} />
    case types.GENERIC:
      return <GenericModal data={data} />
    case types.EMAIL_UPDATED:
      return <EmailUpdatedModal {...data} />
    case types.PAYMENT_SOURCE:
      return <PaymentSourceModal />
    case types.CARD_ADDER:
      return <CardAdderModal {...data} />
    default:
      return null
  }
}

export default withContainer(ModalSwitch)
