import client from 'client'
import get from 'lodash/fp/get'

/* Cuisines */
export const fetchCuisines = () =>
  client(true)
    .get('/cuisines')
    .then(resp => resp.data.items)

/* Profile */
export const fetchProfile = () => client().get('/customers/me')

export const createSetupIntent = () =>
  client().post('/customers/me/payments/sources/intent?type=setup')

export const createPaymentIntent = payload =>
  client().post('/customers/me/payments/sources/intent?type=payment', payload)

export const addPaymentSource = ({ type, details, activated }) =>
  client().post('/customers/me/payments/sources', {
    type,
    details,
    activated,
    name: 'Credit Card',
  })

export const addPaymentSourceLink = payload =>
  client()
    .post('/customers/me/payments/sources/link', payload)
    .then(({ data }) => data)

export const tokenizeReceiptLink = payload =>
  client()
    .post('/customers/me/payments/sources/tokenize', payload)
    .then(({ data }) => data)

export const getPaymentSourceAsset = assetId =>
  client()
    .get(`/customers/me/payments/sources/mdes/asset/${assetId}`)
    .then(({ data }) => data)

export const getCustomerLatestOrders = customerId =>
  client()
    .get(`/customers/${customerId}/orders/recent`)
    .then(({ data }) => data)

export const getCustomerLatestMultiVendorOrders = customerId =>
  client()
    .get(`/customers/${customerId}/orders/multi/recent`)
    .then(({ data }) => data)

export const getGuestLatestMultiVendorOrders = payload =>
  client()
    .post(`/customers/guest/orders/multi/recent`, payload)
    .then(({ data }) => data)

/* Auth */
export const logout = () => client().delete('/auth')

export const lookup = payload =>
  client()
    .post('/auth/lookup', payload)
    .then(({ data }) => data)

export const authCanny = () => client().get('/auth/canny')

/* SMSV */
export const smsv = {
  sendCode: ({ mobile }) =>
    client()
      .post('/customers/me/send_mobile_verification', { mobile })
      .then(({ data }) => data),

  verify: ({ requestId, otp }) =>
    client()
      .put(`/customers/me/send_mobile_verification`, { requestId, otp })
      .then(({ data }) => data),
}

/* Trends */
export const trends = {
  fetch: () => client(true).get('/trends'),
}

/* Utility Functions */
export const getErrorMessage = get('response.data.status.message')
export const fetchNonce = () =>
  client()
    .get('/nonce')
    .then(({ data }) => data.nonces[0])

export const verifyClickToPayResponse = params =>
  client().post(`/orders/clicktopay/verification`, params)
