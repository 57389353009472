import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import pick from 'lodash/fp/pick'

import FormError from 'components.v2/FormError'
import { propTypes as formValidatorPT } from 'utils/validator'
import withContainer from './SendCodeModalContainer'
import { ModalCmpt, Heading, Text, Form, SubmitButton } from './sharedStyled'
import PhoneInputWrapper from 'components.v2/PhoneInputWrapper'
import { PhoneInput } from 'react-international-phone'
import get from 'lodash/get'

const StyledModal = styled(ModalCmpt)`
  &__content {
    max-width: 423px;
  }
`

const CustomFormError = styled(FormError)`
  font-family: Montserrat;
  margin-top: 4px;
`

const SendCodeModal = ({
  fields,
  auth,
  handleHide,
  handleChange,
  handleBlur,
  handleSubmit,
  isAuthenticating,
  sendCodeState,
}) => {
  const { mobile } = JSON.parse(auth)

  return (
    <StyledModal
      isOpen
      contentLabel="Verify your mobile number"
      onRequestClose={handleHide}
    >
      <Form onSubmit={handleSubmit}>
        <Heading>Verify your mobile number</Heading>
        <Text>
          To enable Two-Factor Authentication (2FA), we'll send a code to your
          phone each time you log in.
        </Text>

        <PhoneInputWrapper className="mt-40 mb-40">
          <PhoneInput
            defaultCountry="au"
            value={fields.mobile.value || mobile}
            forceDialCode={true}
            onChange={phone =>
              handleChange({ target: { name: 'mobile', value: phone } })
            }
            onBlur={handleBlur}
            name="mobile"
            placeholder="Mobile number"
            autoFocus
          />
          {get(fields, 'mobile.error') && (
            <CustomFormError>{get(fields, 'mobile.error')}</CustomFormError>
          )}
        </PhoneInputWrapper>

        <SubmitButton
          variant="primary"
          type="submit"
          disabled={isAuthenticating || sendCodeState.isLoading}
          large
          largeText
        >
          {isAuthenticating || sendCodeState.isLoading
            ? 'Processing…'
            : 'Send code'}
        </SubmitButton>
      </Form>
    </StyledModal>
  )
}

SendCodeModal.propTypes = {
  ...pick(
    ['handleChange', 'handleBlur', 'handleSubmit', 'fields', 'errors'],
    formValidatorPT,
  ),
  handleHide: PT.func.isRequired,
  auth: PT.string.isRequired,
}

export default withContainer(SendCodeModal)
