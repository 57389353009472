import {
  dispatchLookup,
  selectIsLookingUp,
  login,
  selectError,
  selectIsAuthenticating,
  clearError,
} from 'auth/modules/auth'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import get from 'lodash/get'
import Cookies from 'js-cookie'

import notif from 'services/notification'
import { show, types } from 'store/modals'
import { unsetGuestCheckoutEmail } from 'store/guest-checkout'
import {
  withFormValidator,
  isRequired,
  isEmail,
  composeValidators as composeV,
} from 'utils/validator'
import getHistory from 'services/history'
import { COOKIE_REDIRECT_PATH } from 'const/cookie'
import noScroll from 'no-scroll'

export default compose(
  connect(
    state => ({
      asyncError: selectError(state),
      isLookingUp: selectIsLookingUp(state),
      isAuthenticating: selectIsAuthenticating(state),
    }),
    (dispatch, { onSuccess = () => {}, hideBackButton = false, hideLoginButton = false, noRedirect = false }) => ({
      onSubmit: ({ email, password }) => {
        dispatch(dispatchLookup(email)).then(response => {
          if (response.usernameExists) {
            const onAfterSuccess = () => {
              onSuccess()
              notif.success({ title: 'Login successful' })

              localStorage.removeItem('dormant-user')
              const redirectPath = Cookies.get(COOKIE_REDIRECT_PATH)
              if (redirectPath) {
                Cookies.remove(COOKIE_REDIRECT_PATH)
                getHistory().push(redirectPath)
              }

              noScroll.off()
            }

            dispatch(login({ email, password }))
              .then(dispatch(unsetGuestCheckoutEmail()))
              .then(onAfterSuccess)
              .catch(err => {
                const errorCode = get(err, 'response.data.status.code', 400)

                switch (errorCode) {
                  case 2400010:
                    localStorage.setItem('dormant-user', email)
                    window.location.replace('/reset-account')
                    break
                  case 110013:
                    dispatch(
                      show(types.SMS_VERIFY, {
                        redirectFrom: types.LOGIN_V2,
                        auth: JSON.stringify({ email, password }),
                        onSuccess: onAfterSuccess,
                      }),
                    )
                    break
                  default:
                    notif.error({
                      title: get(
                        err,
                        'response.data.status.message',
                        'Login failed!',
                      ),
                      hasCloseButton: true,
                    })
                }
              })
          } else {
            if (noRedirect)
              notif.error({
                title: 'User not found!',
                hasCloseButton: true,
              })
            else
              dispatch(show(types.REGISTER_V2, { email }))
          }
        })
      },
      clearAsyncError: () => dispatch(clearError()),
      showForgotPassModal: () => dispatch(show(types.FORGOT_V2, { hideBackButton, hideLoginButton })),
    }),
  ),

  withProps({
    onSubmitFail: () =>
      notif.error({
        title: 'Login failed',
        message: 'please check the form errors',
      }),
  }),

  withFormValidator({
    email: composeV(isRequired, isEmail)('Email'),
    password: isRequired('Password'),
  }),
)
