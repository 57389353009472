import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import times from 'lodash/times'
import withContainer from './CodeInputContainer'

const CodeInput = ({
  _value,
  handleChange,
  handleFocus,
  handleKeyDown,
  inputRefs,
  className,
  disabled = false,
}) => (
  <div className={`flex justify-center ${className}`}>
    {times(4, i => (
      <NumberInput
        key={i}
        ref={inputRefs[i]}
        onFocus={handleFocus(i)}
        onKeyDown={handleKeyDown(i)}
        onChange={handleChange(i)}
        value={_value[i] || ''}
        disabled={disabled}
      />
    ))}
  </div>
)

CodeInput.propTypes = {
  _value: PT.string,
  handleChange: PT.func.isRequired,
  handleFocus: PT.func.isRequired,
  handleKeyDown: PT.func.isRequired,
  inputRefs: PT.arrayOf(PT.object).isRequired,
  className: PT.string,
}

const NumberInput = styled.input.attrs({ required: true })`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 44px;
  height: 52px;
  background-color: ${p => p.theme.colors.gray7};
  font-size: 24px;
  text-align: center;
  outline: none;
  caret-color: var(--Orange-0, #f26722);

  & + & {
    margin-left: 8px;
  }
`

export default withContainer(CodeInput)
