import axios from 'axios'
import axiosRetry from 'axios-retry'
import identity from 'lodash/fp/identity'
import get from 'lodash/fp/get'
import isNode from 'detect-node'

import config from '../config'
import notif from 'services/notification'
import { metaDataManager } from '../services/bugsnag-client'
import { getStore } from 'App'
import { selectIsAuthenticated, logout } from '../auth/modules/auth'
import _get from 'lodash/get'

const saveDataForReport = errorType => e => {
  metaDataManager.set({
    errorType,
    url: get(['config', 'url'], e),
    payload: get(['config', 'data'], e),
    errorCode: get(['response', 'status'], e),
    errorResponse: get(['response', 'data'], e),
    allowedOrigin:
      get(['response', 'headers', 'Access-Control-Allow-Origin'], e) ||
      get(['response', 'headers', 'access-control-allow-origin'], e),
    isClient: !isNode,
    isLoggedIn: selectIsAuthenticated(getStore().getState()),
  })

  return checkAuth(e)
}

const getUrl = (config, url) => {
  const [urlPrefix, version] = url.split(':')

  if (version === 'v3') return `${config.API_V3_URL}${urlPrefix}`
  else return `${config.API_URL}${url}`
}

const getAxiosClient = async (noToken, method, url, options, data) => {
  const getValidToken = require('../auth/helpers').getValidToken

  const token = noToken ? '' : await getValidToken()

  const axiosInstance = axios.create({
    headers: {
      'x-btq-client-id': config.CLIENT_ID,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  })

  // https://github.com/softonic/axios-retry/issues/87
  const retryDelay = (retryNumber = 0) => {
    const seconds = Math.pow(2, retryNumber) * 1000
    const randomMs = 1000 * Math.random()
    return seconds + randomMs
  }

  axiosRetry(axiosInstance, {
    retries: 2,
    retryDelay,
    // retry on Network Error & 5xx responses
    retryCondition: axiosRetry.isRetryableError,
    onMaxRetryTimesExceeded: (error, retryCount) => {
      notif.error({
        title: error.message,
        message: 'Please try again',
        error,
      })
    },
  })

  axiosInstance.interceptors.request.use(identity, saveDataForReport('request'))
  axiosInstance.interceptors.response.use(
    identity,
    saveDataForReport('response'),
  )

  const requestUrl = getUrl(config, url)
  if (data) {
    return axiosInstance[method](requestUrl, data, options)
  } else {
    return axiosInstance[method](requestUrl, options)
  }
}

const getServerAxiosClient = (method, url, options, data) => {
  const axiosInstance = axios.create({
    headers: {
      'x-btq-client-id': config.CLIENT_ID,
    },
  })

  // https://github.com/softonic/axios-retry/issues/87
  const retryDelay = (retryNumber = 0) => {
    const seconds = Math.pow(2, retryNumber) * 1000
    const randomMs = 1000 * Math.random()
    return seconds + randomMs
  }

  axiosRetry(axiosInstance, {
    retries: 2,
    retryDelay,
    // retry on Network Error & 5xx responses
    retryCondition: axiosRetry.isRetryableError,
    onMaxRetryTimesExceeded: (error, retryCount) => {
      notif.error({
        title: error.message,
        message: 'Please try again',
        error,
      })
    },
  })

  axiosInstance.interceptors.request.use(identity, saveDataForReport)
  axiosInstance.interceptors.response.use(identity, saveDataForReport)

  const requestUrl = getUrl(config, url)
  if (data) {
    return axiosInstance[method](requestUrl, data, options)
  } else {
    return axiosInstance[method](requestUrl, options)
  }
}

const checkAuth = err => {
  if (_get(err, 'response.data.status.code') === 150200) {
    notif.error({
      message: _get(err, 'response.data.status.message'),
    })
    getStore().dispatch(logout())
    return Promise.resolve()
  }
  return Promise.reject(err)
}

const client = noToken => {
  if (typeof window !== 'object') {
    return {
      get: (url, options = {}) => getServerAxiosClient('get', url, options),
      post: (url, data, options = {}) =>
        getServerAxiosClient('post', url, options, data),
      put: (url, data, options = {}) =>
        getServerAxiosClient('put', url, options, data),
      delete: (url, options = {}) =>
        getServerAxiosClient('delete', url, options),
    }
  }
  return {
    get: (url, options = {}) => getAxiosClient(noToken, 'get', url, options),
    post: (url, data, options = {}) =>
      getAxiosClient(noToken, 'post', url, options, data),
    put: (url, data, options = {}) =>
      getAxiosClient(noToken, 'put', url, options, data),
    delete: (url, options = {}) =>
      getAxiosClient(noToken, 'delete', url, options),
  }
}

export default client
