import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import get from 'lodash/get'
import values from 'lodash/values'
import Decimal from 'decimal.js'

import { show, types } from 'store/modals'
import { selectMultiVendorOrders } from 'pages/order/modules/multi.vendor.order'

export default compose(
  withRouter,
  connect(
    state => {
      const vendorId = get(state, 'vendor.menu.id')

      const totalSingleOrders = values(get(state, 'order.orders'))
        .reduce((prev, cur) => [...prev, ...get(cur, 'orders', [])], [])
        .reduce((acc, x) => acc.plus(get(x, 'quantity')), new Decimal(0))
        .toNumber()

      const multiVendorOrder = selectMultiVendorOrders(state)

      const totalMultiVendorOrders = multiVendorOrder
        .reduce((prev, cur) => [...prev, ...get(cur, 'orders', [])], [])
        .reduce((acc, x) => acc.plus(get(x, 'quantity')), new Decimal(0))
        .toNumber()

      const orderParty = get(state, `orderParty.parties.${vendorId}`, null)

      return {
        totalSingleOrders,
        totalMultiVendorOrders,
        orderParty,
      }
    },

    dispatch => ({ showLoginModal: () => dispatch(show(types.LOGIN_V2)) }),
  ),
)
