import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import Input from 'components.v2/Input'
import FormError from 'components.v2/FormError'
import { Button } from 'common/auth/Shared/sharedStyled'
import withContainer from './RegisterFormLastStepContainer'
import { formPropTypes } from 'common/auth/Shared/auth.helpers'
import StylableModal from 'components.v2/Modal/StylableModal'
import get from 'lodash/get'
import media from 'utils/media'

const StyledModal = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
    width: 100%;
    max-width: 391px;
    overflow-y: hidden;
    ${media.phone`
      width: calc(100% - 30px);
    `}
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const Form = styled.form`
  padding: 32px;
  display: flex;
  flex-direction: column;

  & > .input-containers {
    display: flex;
    flex-direction: column;
    margin: 24px 0;
    gap: 16px;

    & .hidden {
      display: none !important;
    }
  }

  & input {
    background: #f2f2f2;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    border: none;

    & ::placeholder {
      color: var(--charcoal-2, #999);
    }

    border-radius: 8px;
  }
`

export const Heading = styled.h1`
  color: var(--Black, #000000);
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
`

export const Text = styled.p`
  color: var(--Gray-1, #666666);
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`

const SubmitButton = styled(Button)`
  background: #f26722;
  border-radius: 8px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  height: 52px;

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`

const CustomFormError = styled(FormError)`
  font-family: Montserrat;
  margin-top: 4px;
`

const RegisterForm = ({
  fields,
  isAuthenticating,
  handleSubmit,
  handleChange,
  handleBlur,
  handleHide,
  className,
  actionsReversed,
}) => {
  return (
    <StyledModal
      isOpen
      contentLabel="Enter verification code"
      onRequestClose={handleHide}
    >
      <Form onSubmit={handleSubmit} className={`${className} text-left`}>
        <Heading>Last step!</Heading>
        <Text>Tell us a little bit more about you</Text>
        <div className="input-containers">
          <div>
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={fields.firstName.value}
              hasError={!!fields.firstName.error}
              className="block w-full"
              type="text"
              name="firstName"
              placeholder="First name"
            />
            {get(fields, 'firstName.error') && (
              <CustomFormError>
                {get(fields, 'firstName.error')}
              </CustomFormError>
            )}
          </div>
          <div>
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={fields.lastName.value}
              hasError={!!fields.lastName.error}
              className="block w-full"
              type="text"
              name="lastName"
              placeholder="Last name"
            />
            {get(fields, 'lastName.error') && (
              <CustomFormError>{get(fields, 'lastName.error')}</CustomFormError>
            )}
          </div>
          <div>
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={fields.password.value}
              hasError={!!fields.password.error}
              className="block w-full"
              type="password"
              name="password"
              placeholder="Password"
            />
            {get(fields, 'password.error') && (
              <CustomFormError>{get(fields, 'password.error')}</CustomFormError>
            )}
          </div>
          <div>
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={fields.referralCode.value}
              hasError={!!fields.referralCode.error}
              className="block w-full"
              type="text"
              name="referralCode"
              placeholder="Referral code (Optional)"
            />
          </div>
        </div>
        <SubmitButton
          variant="primary"
          type="submit"
          disabled={isAuthenticating}
          actionsReversed={actionsReversed}
          className="w-full"
        >
          {isAuthenticating ? 'Processing…' : 'Done'}
        </SubmitButton>
      </Form>
    </StyledModal>
  )
}

RegisterForm.propTypes = {
  ...formPropTypes,
  handleHide: PT.func.isRequired,
}

export default withContainer(RegisterForm)
