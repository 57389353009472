import { connect } from 'react-redux'
import { compose, withProps, withState } from 'recompose'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import Cookies from 'js-cookie'

import { getErrorMessage } from 'api'
import notif from 'services/notification'
import { show, types, hide } from 'store/modals'
import noScroll from 'no-scroll'

import {
  register,
  selectError,
  selectIsRegistering,
  clearError,
} from 'auth/modules/auth'

import { unsetGuestCheckoutEmail } from 'store/guest-checkout'
import {
  withFormValidator,
  isRequired,
  isName,
  composeValidators as composeV,
} from 'utils/validator'
import getHistory from 'services/history'
import { COOKIE_REDIRECT_PATH } from 'const/cookie'

export default compose(
  connect(
    state => ({
      asyncError: selectError(state),
      isAuthenticating: selectIsRegistering(state),
    }),
    (dispatch, { onSuccess = () => {}, email, mobile }) => ({
      onSubmit: ({ referralCode: referredBy, ...fields }) => {
        const onAfterSuccess = () => {
          onSuccess()
          notif.success({ title: 'Registration successful' })

          const redirectPath = Cookies.get(COOKIE_REDIRECT_PATH)
          if (redirectPath) {
            Cookies.remove(COOKIE_REDIRECT_PATH)
            getHistory().push(redirectPath)
          }
        }

        dispatch(register({ referredBy, email, mobile, ...fields }))
          .then(dispatch(unsetGuestCheckoutEmail()))
          .then(onAfterSuccess)
          .then(dispatch(hide()))
          .catch(error => {
            const errorMessage = error.response.data.message

            switch (errorMessage) {
              case 'OTP is required.':
                dispatch(
                  show(types.SMS_VERIFY, {
                    redirectFrom: types.REGISTER_V2,
                    auth: JSON.stringify({
                      referredBy,
                      email,
                      mobile,
                      ...fields,
                    }),
                    onSuccess: onAfterSuccess,
                  }),
                )
                break
              default:
                notif.error({
                  title: 'Registration failed',
                  message: getErrorMessage(error),
                  error,
                })
                throw error
            }
          })
      },
      clearAsyncError: () => dispatch(clearError()),
      handleHide: () => {
        dispatch(hide())
        noScroll.off()
      },
    }),
  ),

  withRouter,

  withProps(({ location }) => {
    return {
      initValues: {
        referralCode:
          qs.parse(location.search, { ignoreQueryPrefix: true }).referralcode ||
          '',
      },
      onSubmitFail: () =>
        notif.error({
          title: 'Registration failed',
          message: 'please check the form errors',
        }),
    }
  }),

  withState(
    ({ location }) =>
      !!qs.parse(location.search, { ignoreQueryPrefix: true }).referralcode,
  ),

  withFormValidator({
    firstName: composeV(isRequired, isName)('First name'),
    lastName: composeV(isRequired, isName)('Last name'),
    password: isRequired('Password'),
    referralCode: null,
  }),
)
