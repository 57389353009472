import React, { useState, useEffect, useRef } from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

import CodeInput from 'components.v2/CodeInput'
import pick from 'lodash/fp/pick'
import { propTypes as formValidatorPT } from 'utils/validator'
import withContainer from './VerifyModalContainer'
import { ModalCmpt, Form, Heading, Text, Link } from './sharedStyled'
import { redactPhoneNumber } from 'utils/redact'
import { types } from 'store/modals'

const StyledModal = styled(ModalCmpt)`
  &__content {
    max-width: 404px;
  }
`

const VerifyModal = ({
  fields,
  auth,
  redirectFrom,
  handleHide,
  handleChange,
  handleBlur,
  handleSubmit,
  handleBack,
  isAuthenticating,
  verifyState,
}) => {
  const form = useRef(null)
  const [requestSent, setRequestSent] = useState(false)
  const { mobile } = JSON.parse(auth)

  useEffect(() => {
    if (fields.verificationCode.value.length === 4 && !requestSent) {
      setRequestSent(true)
      form.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      )
    }

    if (fields.verificationCode.value.length < 4) {
      setRequestSent(false)
    }
  }, [fields.verificationCode, requestSent])

  return (
    <StyledModal
      isOpen
      contentLabel="Enter verification code"
      onRequestClose={handleHide}
    >
      <Form ref={form} onSubmit={handleSubmit} noValidate>
        <Heading>Enter verification code</Heading>
        <Text>
          To seal the deal and complete the verification enter the code we sent
          to&nbsp;
          {redirectFrom === types.LOGIN_V2 && 'you'}
          {redirectFrom === types.REGISTER_V2 && (
            <span>{redactPhoneNumber(mobile)}</span>
          )}
          {redirectFrom === types.SMS_SEND_CODE && (
            <span>{redactPhoneNumber(mobile)}</span>
          )}
          {redirectFrom === 'HOMEPAGE' && (
            <span>{redactPhoneNumber(mobile)}</span>
          )}
        </Text>
        <div className="mt-40 mb-40">
          <CodeInput
            value={fields.verificationCode.value}
            onChange={handleChange}
            onBlur={handleBlur}
            name="verificationCode"
            disabled={isAuthenticating || verifyState.isLoading}
          />
        </div>
        <Link
          type="button"
          onClick={() => handleBack()}
          disabled={isAuthenticating || verifyState.isLoading}
        >
          {redirectFrom === types.LOGIN_V2
            ? 'Contact Support'
            : 'Change Number'}
        </Link>
      </Form>
    </StyledModal>
  )
}

VerifyModal.propTypes = {
  ...pick(
    ['handleChange', 'handleBlur', 'handleSubmit', 'fields', 'errors'],
    formValidatorPT,
  ),
  handleHide: PT.func.isRequired,
  handleBack: PT.func.isRequired,
  auth: PT.string.isRequired,
  redirectFrom: PT.string.isRequired,
}

export default withContainer(VerifyModal)
