import styled from 'styled-components'
import StylableModal from 'components.v2/Modal/StylableModal'
import Button from 'components.v2/Button'
import media from 'utils/media'

export const ModalCmpt = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
    width: 100%;
    ${media.phone`
      width: calc(100% - 30px);
    `}
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const Heading = styled.h1`
  color: var(--Black, #000000);
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
  text-align: center;
`

export const Text = styled.p`
  color: var(--Gray-1, #666666);
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  span {
    font-weight: 700;
  }
`

export const Link = styled.button`
  display: block;
  color: var(--Orange-0, #f26722);
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin: 12px 0;
  text-align: center;
  text-decoration: underline;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const SubmitButton = styled(Button)`
  border: none;
  border-radius: 8px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  padding: 12px 0;
  width: 100%;
`

export const Form = styled.form`
  padding: 40px;
`
