import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { withRouter } from 'react-router-dom'

import { getErrorMessage } from 'api'
import notif from 'services/notification'
import { show, types } from 'store/modals'

import {
  dispatchLookup,
  selectIsLookingUp,
  selectError,
  selectIsRegistering,
  selectIsAuthenticating,
  clearError,
} from 'auth/modules/auth'

import {
  withFormValidator,
  isRequired,
  isEmail,
  isMobileNum,
  composeValidators as composeV,
} from 'utils/validator'

export default compose(
  connect(
    state => ({
      asyncError: selectError(state),
      isLookingUp: selectIsLookingUp(state),
      isAuthenticating:
        selectIsRegistering(state) || selectIsAuthenticating(state),
    }),
    (dispatch, { onSuccess = () => {}, noRedirect = false }) => ({
      onSubmit: ({ ...fields }) => {
        dispatch(dispatchLookup(fields.email))
          .then(response => {
            if (response.usernameExists) {
              if (noRedirect)
                notif.error({
                  title: 'User already exist!',
                  hasCloseButton: true
                })
              else
                dispatch(show(types.LOGIN_V2, { email: fields.email }))
            } else {
              dispatch(
                show(types.REGISTER_V2_LAST_STEP, {
                  email: fields.email,
                  mobile: fields.mobile,
                  onSuccess,
                }),
              )
            }
          })
          .catch(error => {
            notif.error({
              title: 'Account creation failed',
              message: getErrorMessage(error),
              error,
            })
            throw error
          })
      },
      clearAsyncError: () => dispatch(clearError()),
    }),
  ),

  withRouter,

  withProps({
    onSubmitFail: () =>
      notif.error({
        title: 'Registration failed',
        message: 'please check the form errors',
      }),
  }),

  withFormValidator({
    email: composeV(isRequired, isEmail)('Email'),
    mobile: isMobileNum('Mobile'),
  }),
)
