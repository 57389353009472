import styled from 'styled-components'

/** @component */
export default styled.span`
  display: block;
  color: ${p => p.theme.colors.primary};
  font-size: 12px;

  ::before {
    content: '* ';
  }
`
