import { connect } from 'react-redux'
import { compose, withProps, mapProps, withState } from 'recompose'
import SocialLogin from 'react-social-login'
import omit from 'lodash/fp/omit'
import shortid from 'shortid'

import { login } from 'auth/modules/auth'
import CONFIG from 'app-config'
import notif from 'services/notification'
import noScroll from 'no-scroll'
import get from 'lodash/get'
import { show, types } from 'store/modals'

export default compose(
  connect(null, { login, show }),

  withState('key', 'changeKey', shortid.generate()),

  withProps(({ login, show, provider, onSuccess = () => {}, changeKey }) => ({
    provider,
    variant: provider,
    version: 'v14.0',
    appId: {
      facebook: CONFIG.FACEBOOK_APP_ID,
      google: CONFIG.GOOGLE_CLIENT_ID,
    }[provider],
    onLoginSuccess: ({ token: t }) => {
      const onAfterSuccess = () => {
        onSuccess()
        notif.success({ title: 'Login successful' })
        noScroll.off()
      }

      login({
        email: '',
        password: {
          facebook: `facebook:${t.accessToken}`,
        }[provider],
      })
        .then(onAfterSuccess)
        .catch(error => {
          const errorCode = get(error, 'response.data.status.code', 400)

          switch (errorCode) {
            case 110012:
              show(types.SMS_SEND_CODE, {
                redirectFrom: types.LOGIN_V2,
                auth: JSON.stringify({
                  email: '',
                  password: {
                    facebook: `facebook:${t.accessToken}`,
                  }[provider],
                }),
                onSuccess: onAfterSuccess,
              })
              break
            case 110013:
              show(types.SMS_VERIFY, {
                redirectFrom: types.LOGIN_V2,
                auth: JSON.stringify({
                  email: '',
                  password: {
                    facebook: `facebook:${t.accessToken}`,
                  }[provider],
                }),
                onSuccess: onAfterSuccess,
              })
              break
            default:
              notif.error({
                title: 'Login failed',
                message: 'please try again',
                error,
              })
          }
        })
    },
    onLoginFailure: error => {
      notif.error({
        title: 'Authentication failed',
        message: 'please try again',
        error,
      })

      changeKey(shortid.generate())
    },
  })),

  SocialLogin,

  withProps(props => ({
    onClick: props.triggerLogin,
    loading: props.isLoginQueued || props.isLoginOngoing,
  })),

  mapProps(omit(['onSuccess'])),
)
